import aboutimg from 'assets/img/section4-img4.png';

const AboutUs = () => {
    return (
        <>
            <div className="container">
                <h4 className="text-3572EF mb-2">About Us</h4>
                <p className="text-dark">Bevan.ai is a dedicated ChatGPT 3.5 model trained to help users gain intelligent insights into future and current use of digital and data across the entire NHS in England.  </p>
                <p className="text-dark">Currently, much of this information is locked in unstructured board papers, usually PDFs, making it hard to find and extract meaning from.</p>
                <p className="text-dark">With Bevan.ai, you will be able to gain insights faster and easier by asking our AI chatbot anything from digital adoption market trends to specific questions about an NHS trusts’ current digital requirements.</p>
                <div className="w-100 my-4 text-center">
                <img src={aboutimg} className="w-40" alt="" />
                </div>
                <p className="text-dark">Bevan.ai has been trained against a mass of local NHS trust and ICB plans, strategies and board papers.</p>
                <p className="text-dark">Entering Beta in September 2024 with local NHS digital leaders from Digital Health Networks, the community of 7,000 NHS leaders. </p>
                <p className="text-dark">Developed by Digital Health the specialist B2B news, events, and intelligence publisher dedicated to providing insight into the digital future of health and care. </p>
            </div >
        </>
    );
};

export default AboutUs;