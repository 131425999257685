
const PrivacyPolicy = () => {
    return (
        <>
            <div className="container">
                <h4 className="text-3572EF">Privacy policy</h4>
                <p className="text-dark">Digital Health Intelligence Limited are committed to protecting and respecting your privacy.</p>

                <p className="text-dark">This policy (and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

                <p className="text-dark">If you have any questions about this policy and our privacy practices, please email <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a> or write to The Data Controller, Kemp House, 152-160 City Road, London, EC1V 2NX.  Alternatively, you can call us on 020 7785 6900.</p>

                <p className="text-dark">The data controller is Digital Health Intelligence Limited of Kemp House, 152-160 City Road, London, EC1V 2NX.</p >

                <p className="text-dark">Our nominated representative for the purpose of Data Protection is Katerina Loucka.</p>

                <h4 className="text-3572EF">Who are we? </h4>
                <p className="text-dark">Digital Health provides independent B2B news, research, networks and events focused on the fast-evolving field of health IT and digital health. Our news website, <a href="https://digitalhealth.net" target="_blank" rel="noreferrer" className="text-primary">www.digitalhealth.net</a> is free to access and provides daily news, features and insight. In addition, readers are able to sign-up to receive a range of targeted Digital Health newsletters and publications.</p>

                <p className="text-dark">Digital Health hosts regular events and webinars. These are targeted at senior and aspiring digital leaders in the NHS and include a mix of free and paid-for events. Some events are open to commercial suppliers to attend.</p>

                <h4 className="text-3572EF">Information we may collect about you</h4>
                We may collect and process the following data about you:

                <p className="text-dark">Financial Information – Payment information gathered during the registration process – including but not limited to, credit card numbers, financial institutions, and expiration dates – is considered financial. This information is not distributed under any circumstance.</p>

                <p className="text-dark">Professional Information – Data that customers provide to Digital Health or MailChimp through various methods of communications with Digital Health is considered professional information that is stored and managed for Digital Health’s internal use. Professional information includes, but is not limited to, name, address, company name, company address, phone number, fax number, email address, as well as questions answered on survey or registration forms. Data captured during a customer’s visit to a Digital Health website, as well as during a visit with MailChimp, Discourse (Networks website), is also considered professional information and may also be stored for Digital Health’s internal use.</p>

                <h4 className="text-3572EF">IP addresses</h4>
                <p className="text-dark">We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>

                <h4 className="text-3572EF">Where we store your information</h4>
                <p className="text-dark">Your personal data is stored within our database within the UK in a Salesforce CRM system and in our MailChimp newsletter distribution system.  Your personal preferences and the newsletters or notifications that you are subscribed to are recorded in these systems to ensure that you receive the right information from Digital Health.</p>

                <h4 className="text-3572EF">How we use your information</h4>
                <p className="text-dark">We use information held about you in the following ways:</p>
                <ul>
                    <li>To ensure that content from our site is presented in the most effective manner for you and for your computer.</li>
                    <li>To provide you with information, products or services that you request from us or which we feel may interest you.</li>
                    <li>To carry out our obligations arising from any contracts entered into between you and us.</li>
                    <li>To allow you to participate in interactive features of our service, when you choose to do so.</li>
                    <li>To notify you about changes to our service.</li>
                </ul>

                <p className="text-dark">If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale to you. If you do not want us to use your data in this way, you can unsubscribe to any emails by using the unsubscribe feature at the bottom of all of our emails.</p>

                <h4 className="text-3572EF">How we protect your data</h4>
                <p className="text-dark">We have put in place security procedures and technical and organisational measures to safeguard your personal information. Access to all internal data servers is limited to the specialist data personnel and is controlled via database structure authentication using defined policies to control password expiry and renewal.</p>

                <h4 className="text-3572EF">Your rights</h4>
                <h6 className="text-3572EF">Legal basis for collecting, storing and processing personal data</h6>
                <p className="text-dark">Digital health uses several legal basis for collecting, sorting and using your personal information. Much of our data will be opted in use for the legal basis, but we also utilise legitimate interest as a legal basis for the use and processing of personal information. For legitimate interest we have and will test to make sure we feel it is necessary to use under legitimate interest and therefore opt in is not required. You can read more about legitimate interest <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/lawful-basis/a-guide-to-lawful-basis/lawful-basis-for-processing/legitimate-interests/" target="_blank" rel="noreferrer" className="text-primary">here</a>.</p>

                <p className="text-dark">You have the right to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by ticking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>

                <p className="text-dark">Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>

                <h4 className="text-3572EF">Removing your consent</h4>
                <p className="text-dark">By submitting your personal information you consent to the use of that information as set out in this policy. You may withdraw your consent at any time by writing to us either by emailing <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a> or by post to The Data Controller, Digital Health Intelligence Limited of Kemp House, 152-160 City Road, London, EC1V 2NX.</p>
                <h4 className="text-3572EF">Access to your information</h4>
                <p className="text-dark">You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please send an email to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>
                <p className="text-dark">We want to ensure that your personal information is accurate and up to date. If any of the information that you have provided to us changes, for example if you change your email address or name, please let us know the correct details by email to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>. You may ask us, or we may ask you, to correct information you or we think is inaccurate, and you may also ask us to remove information which is inaccurate.</p>
                <h4 className="text-3572EF">Changes to our Privacy Policy</h4>
                <p className="text-dark">Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by email. This policy was last reviewed in April 2021.</p>

                <h4 className="text-3572EF">Contact us</h4>
                <p className="text-dark">Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to <a href="mailto:datacontroller@digitalhealth.net" className="text-primary">datacontroller@digitalhealth.net</a>.</p>
            </div >
        </>
    );
};

export default PrivacyPolicy;