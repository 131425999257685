import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Col,
} from "reactstrap";

import zxcvbn from 'zxcvbn';
import Logo from 'components/Logo';
import AuthFooter from 'components/Footers/AuthFooter';
import AuthAnimation from './AuthAnimation';
import { register } from 'network/ApiAxios';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  let isRegistrationActive = false;
  const settingsString = localStorage.getItem("settings");
  const settings = JSON.parse(settingsString);
  isRegistrationActive = settings && settings.is_registeration_disabled === "no";

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const evaluation = zxcvbn(newPassword);
    switch (evaluation.score) {
      case 0:
      case 1:
        setPasswordStrength('weak');
        break;
      case 2:
        setPasswordStrength('fair');
        break;
      case 3:
        setPasswordStrength('good');
        break;
      case 4:
        setPasswordStrength('strong');
        break;
      default:
        setPasswordStrength('');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    let newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Full Name is required.";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    }
    if (!password.trim()) {
      newErrors.password = "Password is required.";
    }
    if (!termsAccepted) {
      newErrors.terms = "You must agree to the terms and conditions to register.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const data = await register(name, email, password);
      if (data.success) {
        setErrors({});
      } else {
        setErrors({ form: data.message });
      }
    }
  };

  return (
    <>
      <Col xl="4" lg="5" md="7" className="d-flex flex-column mx-lg-0 mx-auto">
        <Logo />
        {isRegistrationActive ? (
          <Card className="card-plain">
            <CardHeader className="pb-0 text-left">
              <h4 className="font-weight-bolder">Sign Up</h4>
            </CardHeader>
            <CardBody className="py-1">
              <Form role="form" onSubmit={handleRegister}>
                <FormGroup>
                  <Label className="">Full Name</Label>
                  <Input
                    className="mb-3" name='full_name'
                    placeholder="Enter first & last name" type="text"
                    onChange={e => setName(e.target.value)}
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label className="">Email</Label>
                  <Input className="mb-3"
                    placeholder="Enter valid email address"
                    name='email'
                    type="email"
                    autoComplete="new-email"
                    onChange={e => setEmail(e.target.value)}
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </FormGroup>
                <FormGroup>
                  <Label className="">Password</Label>
                  <Input className="mb-3"
                    placeholder="Enter strong password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {errors.password && <div className="text-danger">{errors.password}</div>}
                </FormGroup>
                <div className="text-muted font-italic mb-3">
                  <small>
                    Password strength: <span className={`text-${passwordStrength === 'strong' ? 'success' : 'danger'} font-weight-700`}>{passwordStrength}</span>
                  </small>
                  <p className="text-sm mb-0">
                    Please follow this guide for a strong password: <span className="text-sm">Min 6 characters, Atleast a number, a uppercase & a special characters</span>
                  </p>
                </div>
                <div className="form-check form-check-info text-left">
                  <input
                    className="form-check-input"
                    id="checkRegister"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkRegister"
                  >
                    <span className="text-muted">
                      I agree with the{" "}
                      <a href="/terms-and-conditions" className="text-dark font-weight-bolder">
                        Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                {errors.terms && <div className="text-danger">{errors.terms}</div>}
                {errors.form && <div className="text-danger">{errors.form}</div>}
                <div className="text-center">
                  <Button type="submit" className="w-100 mt-4 mb-0" color="custom">
                    Sign up
                  </Button>
                </div>
              </Form>
            </CardBody>
            <CardFooter className="text-center pt-0 px-sm-4 px-1">
              <p className="mb-4 mx-auto">
                Already have an account?
                <a href="/auth/login" className="text-primary font-weight-bold"> Sign in</a>
              </p>
            </CardFooter>
          </Card>
        ) : (
          <Card className="card text-center my-5 shadow-none">
            <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100" >
              <span className="mask bg-none"></span>
              <div className="card-body position-relative z-index-1 h-100 p-5">
                <h6 className="text-warning font-weight-bolder mb-3">Sorry! Registration is currently closed.</h6>
                <a className="btn btn-round btn-outline-dark mb-0" href="/">
                  Back to login
                  <i className="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </Card>
        )}
        <AuthFooter />
      </Col >

      <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-left flex-column">
        <div className="position-relative bg-gradient-dark h-100 m-3 border-radius-lg d-flex flex-column overflow-hidden" >
          <AuthAnimation />
        </div >
      </div >
    </>
  );
};

export default Register;
