import Chat from "views/pages/Chat";
import Register from "views/auth/Register";
import Login from "views/auth/Login";
import ResetPassword from "views/auth/ResetPassword";
import ResetPasswordConfirm from "views/auth/ResetPasswordConfirm";
import Activate from "views/auth/Activate";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import AboutUs from "views/pages/AboutUs";
import TermsConditions from "views/pages/TermsConditions";

var routes = [
  {
    key: 0,
    path: "/c/",
    name: "Chat",
    icon: "fa-solid fa-pen-to-square text-primary",
    component: <Chat />,
    layout: "/chat",
  },
  // {
  //   key: 1,
  //   path: "/c/:id",
  //   name: "Chat",
  //   icon: "fa-solid fa-pen-to-square text-primary",
  //   component: <Chat />,
  //   layout: "/chat",
  // },
  {
    key: 2,
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    key: 3,
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    key: 4,
    path: "/activate/:uid/:token",
    name: "Activate",
    icon: "ni ni-circle-08 text-pink",
    component: <Activate />,
    layout: "/auth",
  },
  {
    key: 5,
    path: "/reset-password",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPassword />,
    layout: "/auth",
  },
  {
    key: 6,
    path: "/reset-password/confirm/:uid/:token",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPasswordConfirm />,
    layout: "/auth",
  },
  {
    key: 7,
    path: "/about-us",
    name: "About Us",
    icon: "ni ni-circle-08 text-pink",
    component: <AboutUs />,
    layout: "/main",
  },
  {
    key: 7,
    path: "/privacy",
    name: "Privacy Policy",
    icon: "ni ni-circle-08 text-pink",
    component: <PrivacyPolicy />,
    layout: "/main",
  },
  {
    key: 7,
    path: "/terms",
    name: "Terms and Conditions",
    icon: "ni ni-circle-08 text-pink",
    component: <TermsConditions />,
    layout: "/main",
  },
];
export default routes;
