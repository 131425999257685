import React, { useState } from 'react';
import { Row, Col, Spinner } from 'reactstrap';

import Feedback from './Feedback';
import whitelogoicon from 'assets/img/bevan-icon.png';

const ChatMessageDisplay = ({ mapping, scrollRef, sessionId, regenerateResponse, regMsdId }) => {
    const [visibleFeedbackSet, setVisibleFeedbackSet] = useState(new Set());
    const messages = Object.values(mapping);

    const handleMouseOver = (id) => {
        setVisibleFeedbackSet(new Set(visibleFeedbackSet.add(id)));
    };

    const handleMouseOut = (id) => {
        visibleFeedbackSet.delete(id);
        setVisibleFeedbackSet(new Set(visibleFeedbackSet));
    };

    return (
        <div className="h-100 pt-4" ref={scrollRef}>
            {messages.map((entry, index) => (
                <Row
                    key={entry.id}
                    className="py-3 justify-content-center"
                    onMouseOver={() => handleMouseOver(entry.id)}
                    onMouseOut={() => handleMouseOut(entry.id)}
                >
                    <Col xl="9">
                        <div className="d-flex">
                            <div className="me-3">
                                {entry.message.author.role === 'user' ?
                                    <div className="icon icon-sm icon-shape bg-secondary shadow-primary text-center rounded-circle">
                                        <i className="fa fa-user text-sm opacity-10 text-white" aria-hidden="true"></i>
                                    </div>
                                    :
                                    <div className="icon icon-sm icon-shape bg-gradient-custom shadow-primary text-center rounded-circle">
                                        {/* <i className="ni ni-atom text-lg opacity-10 text-white mt-1" aria-hidden="true"></i> */}
                                        <img src={whitelogoicon} className="w-90 px-1 py-1" alt="" />

                                    </div>
                                }
                            </div>
                            <div className='w-100'>
                                <div className="justify-content-between align-items-center">
                                    <h5 className="text-black mb-0 fs-6">
                                        {entry.message.author.role === 'user' ? 'You' : 'Bevan'}
                                    </h5>
                                </div>
                                <div className='w-100'>
                                    {regMsdId === entry.id
                                        ? <Spinner color='dark' size="sm" type="grow" />
                                        : <div className="text-message mb-1 text-black" data-message-author-role={entry.message.author.role} data-message-id={entry.message.id} dangerouslySetInnerHTML={{
                                            __html: entry.message.content.parts.join(" ").replace(/```html|```/g, '').trim()
                                                .replace(/<h1(?=[^>]*>)/g, '<h1 class="h3 text-black"')
                                                .replace(/<h2(?=[^>]*>)/g, '<h2 class="h4 text-black"')
                                                .replace(/<h3(?=[^>]*>)/g, '<h3 class="h5 text-black"')
                                                .replace(/<h4(?=[^>]*>)/g, '<h4 class="h6 text-black"')
                                        }}></div>

                                    }
                                    {entry.message.author.role === 'assistant' ?
                                        <Feedback
                                            isVisible={visibleFeedbackSet.has(entry.id)}
                                            isLastMessage={index === messages.length - 1}
                                            messageId={entry.message.id}
                                            sessionId={sessionId}
                                            regenerateResponse={regenerateResponse}
                                        /> : ''}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default ChatMessageDisplay;
