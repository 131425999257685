import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Col, } from "reactstrap";

import { Auth } from '../Auth';
import { conversation } from "network/ApiAxios";
import { useChatContext } from 'contexts/ChatContext';
import ChatMessageDisplay from 'components/Chat/ChatMessageDisplay';
import logoicon from 'assets/img/bevan-icon.png';


const Chat = (props) => {
    const { refreshConversations } = useChatContext();
    const { id } = props;
    const navigate = useNavigate()
    const token = localStorage.getItem('token');
    const [messages, setMessages] = useState([]);
    const [userPrompt, setUserPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [regenerateMessageId, setRegenerateMessageId] = useState(null);
    const scrollRef = useRef(null);
    const formRef = useRef(null);

    useEffect(() => {
        if (id) {
            //console.log(`Fetching conversation`);
        } else {
            //console.log('No ID provided in the URL');
        }
    }, [id]);

    const createUserMessage = (queryText) => {
        return {
            id: `user-${new Date().getTime()}`,
            message: {
                id: `user-${new Date().getTime()}`,
                author: {
                    role: "user"
                },
                create_time: new Date().toISOString(),
                update_time: new Date().toISOString(),
                content: {
                    content_type: "text",
                    parts: [
                        queryText
                    ]
                },
            },
            parent: null,
            children: []
        };
    };

    useEffect(() => {
        if (id) {
            const getConversation = async () => {
                try {
                    const data = await conversation(token, id);
                    setMessages(data.mapping ? Object.values(data.mapping) : []);
                } catch (error) {
                    console.error('Failed to fetch conversation:', error);
                    setMessages([]);
                }

                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            };
            getConversation();
        }
    }, [id, token, regenerateMessageId]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSubmit = async (event, query = null, messageId = null, parentId = null) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (query == null) {
            query = userPrompt.trim();
        }

        if (!query) return;
        setIsLoading(true);

        const messageInput = formRef.current.elements.messageInput;
        if (!messageInput) {
            console.error('Message input not found');
            setIsLoading(false);
            return;
        }

        const messageValue = query ? query : messageInput.value;

        const assistantMessages = document.querySelectorAll('div[data-message-author-role="assistant"]');
        const lastAssistantMessage = assistantMessages[assistantMessages.length - 1];
        const pid = lastAssistantMessage ? lastAssistantMessage.getAttribute('data-message-id') : null;

        const urlParts = window.location.href.split('/');
        const getSid = urlParts[urlParts.length - 1];
        const sid = getSid ? getSid : null;
        const mid = messageId ? messageId : null;

        const userMessage = createUserMessage(userPrompt);

        if (messageId) {
            setRegenerateMessageId(messageId)
        } else {
            setMessages(prevMessages => [...prevMessages, userMessage]);
        }

        try {
            const newMessage = await conversation(
                token, '', messageValue, pid, sid, mid
            );
            if (newMessage && newMessage.success) {
                if (mid == null) {
                    setMessages(prevMessages => {
                        const updatedMessages = Array.isArray(prevMessages) ? [...prevMessages] : [];
                        if (newMessage.message && newMessage.message.message) {
                            updatedMessages.push(newMessage.message);
                            setUserPrompt('');
                        }
                        return updatedMessages;
                    });
                    if (newMessage.session_id) {
                        navigate(`/c/${newMessage.session_id}`);
                    }
                } else {
                    refreshConversations();
                }
            } else {
                console.error("Failed to add new message: API response unsuccessful");
            }
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setIsLoading(false);
            setRegenerateMessageId(null);
            refreshConversations();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <>
            <div className="pt-md-0 mb-3 h-100 d-flex w-100 px-3 px-lg-1 bg-white" style={{ maxHeight: '85vh', overflowY: 'auto', overflowX: 'hidden' }} ref={scrollRef}>
                <Row className="mt-2 h-100 flex-grow-1 d-flex">
                    <Col className="h-100 flex-grow-1 d-flex" xl="12">
                        <div className="mb-0 mb-xl-0 h-100 flex-grow-1 d-flex flex-column">
                            <div className="pt-2 flex-grow-1">
                                <div className="p-0 h-100 position-relative">
                                    {messages && messages.length > 0 ? (
                                        <ChatMessageDisplay
                                            mapping={messages}
                                            sessionId={id}
                                            regenerateResponse={handleSubmit}
                                            regMsdId={regenerateMessageId}
                                        />
                                    ) : (
                                        <div className="flex h-100 flex-col text-center align-content-center text-token-text-primary">
                                            <div className="relative">
                                                <div className="mb-3 h-12 w-12">
                                                    <div className="gizmo-shadow-stroke relative flex h-100 text-center align-content-center rounded-full bg-token-main-surface-primary text-token-text-primary">
                                                        <div className="mx-auto">
                                                        <img src={logoicon} className="w-4" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5 text-2xl font-medium text-black">How can I help you today?</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div >
            <div className="justify-content-center sticky-bottom px-4 bg-white">
                <form ref={formRef} className="align-items-center w-75 mx-auto" onSubmit={handleSubmit}>
                    <div className="d-flex">
                        <div className="input-group">
                            <textarea
                                name="messageInput"
                                className="form-control p-3"
                                placeholder="Message Bevan"
                                aria-label="Message example input"
                                aria-describedby="button-addon2"
                                value={userPrompt}
                                onChange={e => setUserPrompt(e.target.value)}
                                onKeyDown={handleKeyDown}
                                rows="1"
                            />

                            <button className="btn bg-gradient-dark mb-0 ml-2 text-white" type="submit" disabled={!userPrompt.trim()}>
                                {isLoading ? <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span> : <i className="ni ni-send"></i>}
                            </button>
                        </div>
                    </div>
                </form>
                <p className="text-center text-xs mt-2 mb-0">
                    Bevan can make mistakes. Consider checking important information.
                </p>
            </div>
        </>
    );
};

export default Auth(Chat);
