import React, { useState, useEffect } from 'react';
import { allSettings } from 'network/ApiAxios';


// SettingsContext
const SettingsContext = React.createContext();
export const useSettings = () => React.useContext(SettingsContext);

// SettingsProvider
const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await allSettings();
                localStorage.setItem("settings", JSON.stringify(data));
                setSettings(data);
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        };

        fetchSettings();
    }, []);

    return (
        <SettingsContext.Provider value={{ settings }}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
