import logoimg from '../assets/img/bevanai-mainlogo.png';

const Logo = (props) => {
    return (
        <div className="w-100 mb-2 text-center">
            <img src={logoimg} className="w-70" alt="" /> <span className="align-bottom fs-6">(beta)</span>
        </div>
    );
};

export default Logo;