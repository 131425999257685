import AnimatedText from "components/AnimatedText";

const AuthAnimation = () => {

    const textItems = [
        {
            heading: 'Provide 3 examples',
            text: 'of NHS trusts leading in virtual wards?'
        },
        {
            heading: "How has BLMK ICB",
            text: "managed capital resources 'as outlined in the their Annual Report 2022-2023?'"
        },
        {
            heading: 'What are the key priorities',
            text: "in Liverpool University's digital strategy roadmap?"
        },
        {
            heading: "Summarise Oxford Health's",
            text: "digital priorities from their digital strategy report?"
        },
    ];

    return (
        <>
            <span className="mask bg-gradient-dark opacity-6" style={{
                backgroundImage:
                    "url(" + require("../../assets/img/theme/Group-4-1.svg").default + ")",
                backgroundSize: "cover", backgroundPosition: "left",
            }}></span>
            <div className="top-50 position-absolute mt-n6 px-4">
                <AnimatedText texts={textItems} />
            </div>
        </>
    );
};

export default AuthAnimation;